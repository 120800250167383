import React from "react";
import Signpost from "../components/Signpost/Signpost";

function Performance() {
	return (
		<div className="text-side">
			<h1>Performance</h1>
			<ul className="list">
				<li>
					1 Gbps Full duplex
				</li>
				<li>
					3.5 – 112 MHz channels
				</li>
				<li>
					QPSK – 4096 QAM
				</li>
				<li>
					10, 17, 18, 24 GHz
				</li>
				<li>
					PoE or DC powering 20 – 60 V / 22W
				</li>
			</ul>
			<Signpost prev={{ to: '/market-leader', title: 'Market Leader'}} next={{ to: '/spectral-efficiency', title: 'Spectral ef.'}} />
		</div>
	);
}

export default Performance;

import React from "react";
import Signpost from "../components/Signpost/Signpost";

function Reliability() {
	return (
		<div className="text-side">
			<h1>Reliability</h1>
			<ul className="list">
				<li>
					Resistance to any interference
				</li>
				<li>
					High sensitivity, Hitless ACM and ATPC
				</li>
				<li>
					Surge immunity 4 kV, ESD 8 kV
				</li>
				<li>
					Each unit tested in climatic chamber
				</li>
				<li>
					Designed and produced in Czechia, EU
				</li>
			</ul>
			<Signpost prev={{ to: '/spectral-efficiency', title: 'Spectral ef.'}} next={{ to: '/connectivity', title: 'Connectivity'}} />
		</div>
	);
}

export default Reliability;

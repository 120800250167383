import React from "react";
import Signpost from "../components/Signpost/Signpost";
import SpectralEfficiencyContent from "../components/SpectralEfficiencyContent/SpectralEfficiencyContent";

function SpectralEfficiency() {
	return (
		<>
			<div className="text-side">
				<h1>Spectral efficiency</h1>
				<ul className="list">
					<li>
						Ready for crowded and noisy sites
					</li>
					<li>
						Each piece of spectrum effectively used
					</li>
					<li>
						Built-in spectrum analyzer
					</li>
					<li>
						SW adjustable narrower polarization switch
						instead of fixed duplexer (17, 24 GHz)
					</li>
					<li>
						Asymmetric channels
					</li>
				</ul>
				<Signpost prev={{ to: '/performance', title: 'Performance'}} next={{ to: '/reliability', title: 'Reliability'}} />
			</div>
			<SpectralEfficiencyContent />
		</>
	);
}

export default SpectralEfficiency;

import React from "react";
import './MountingContent.scss';
import videoSrc from './ray3-twist-lock.mp4';

function MountingContent() {
	return (
		<div className="mounting-content">
			<video width="1280" height="720" autoPlay muted loop>
				<source src={videoSrc} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	);
}

export default MountingContent;

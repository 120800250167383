import React from "react";
import { Link } from "react-router-dom";
import './HomeMenu.scss';

function HomeMenu() {
	return (
		<nav className="home-menu">
			<ul className="home-menu__list">
				<li className="home-menu__list__item">
					<Link to="/market-leader" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Market leader
					</Link>
				</li>
				<li className="home-menu__list__item">
					<Link to="/performance" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Performance
					</Link>
				</li>
				<li className="home-menu__list__item">
					<Link to="/spectral-efficiency" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Spectral efficiency
					</Link>
				</li>
			</ul>
			<ul className="home-menu__list">
				<li className="home-menu__list__item">
					<Link to="/reliability" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Reliability
					</Link>
				</li>
				<li className="home-menu__list__item">
					<Link to="/connectivity" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Connectivity
					</Link>
				</li>
				<li className="home-menu__list__item">
					<Link to="/mounting" className="home-menu__link">
						<svg>
							<use xlinkHref="#plus"/>
						</svg>
						Mounting
					</Link>
				</li>
			</ul>
		</nav>
	);
}

export default HomeMenu;

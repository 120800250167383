import React from "react";
import Signpost from "../components/Signpost/Signpost";

function MarketLeader() {
	return (
		<div className="text-side">
			<h1>Market leader</h1>
			<ul className="list">
				<li>
					All state-of-the-art concepts implemented
				</li>
				<li>
					The best spectral efficiency
				</li>
				<li>
					Proofed in all climates
				</li>
				<li>
					Reliability over distance
				</li>
				<li>
					RAy Tools Mobile App
				</li>
			</ul>
			<Signpost prev={{ to: '/', title: 'Home'}} next={{ to: '/performance', title: 'Performance'}} />
		</div>
	);
}

export default MarketLeader;

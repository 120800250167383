import React from "react";
import Signpost from '../Signpost/Signpost';
import './ConnectivityContent.scss';

function ConnectivityContent() {
	return (
		<div className="connectivity-content">
			<div className="connectivity-content__model">
				<div className="connectivity-content__item connectivity-content__item--poe">
					Ethernet 1 / PoE
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--sfp">
					Ethernet 2 (SFP)
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--usb">
					Service USB
					<svg>
						<use xlinkHref="#line"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--voltmeter">
					Voltmeter RSS Contacts
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
			</div>
			<Signpost prev={{ to: '/reliability', title: 'Reliability'}} next={{ to: '/mounting', title: 'Mounting'}} absolute />
		</div>
	);
}

export default ConnectivityContent;

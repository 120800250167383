import React, { useState } from "react";
import './Header.scss';
import Nav from "../Nav/Nav";
import Button from "../Button/Button";
import {Link, useRouteMatch} from "react-router-dom";

function Header() {
	const [navOpen, setNavOpen] = useState(false);

	const toggleNav = () => {
		setNavOpen(!navOpen);
	};

	let isHomepage = useRouteMatch({
		path: '/',
		exact: true
	});

	let headerInnerClass = 'header__inner';
	if (navOpen) {
		headerInnerClass += ' is-open';
	}

	let headerClass = 'header';
	if (!!isHomepage) {
		headerClass += ' is-homepage';
	}

	return (
		<div className={headerClass}>
			<div className={headerInnerClass}>
				<Link to="/" className="header__logo">
					<svg xmlns="http://www.w3.org/2000/svg" width="63" height="20" viewBox="0 0 250 80"><path d="M54.806 40.58c6.596-3.16 11.292-10.72 11.482-19.48C66.034 9.48 57.85 0 47.764 0H0v58h10.34V12.06h37.362c4.376 0 7.928 4 8.118 9.04-.19 5.04-3.806 9.04-8.118 9.04H33.556L54.172 58H68L54.806 40.58zm11.482-19.86v.9-.9z" fill="#cb2633"/><path d="M193.264 18l-17.358 28.1L158.924 18H146l23.35 39.26L155.428 80h12.986L206 18h-12.736z" fill="#9b9b9b"/><path d="M117.948 1.86l-.45-.38-.514-.32-.45-.26-.45-.26-.514-.2-.45-.12-.45-.2-.9-.12h-.834l-.45.06-.45.14-.45.12-.45.2-.45.24-.514.26-.514.26-.448.4-.514.32-.386.38-.45.32-.386.38-.32.38-.322.4-.322.32-.32.44-.322.4-.256.38-.258.44-1.35 2.32-2.826 4.68-3.92 6.42-4.496 7.46-4.756 7.9-4.626 7.64-4.048 6.82-3.212 5.26-1.864 3.14L74 58h12.85l.258-.32 1.476-2.32 2.506-4.16 3.342-5.46 3.79-6.3 3.984-6.42 3.79-6.24 3.278-5.32 2.442-4.06L113 15.28l.386.26 1.542 2.58 2.634 4.3 3.34 5.58 3.792 6.3 3.918 6.42 3.728 6.16 3.148 5.28L137.8 56l1.158 1.88.256.12H152l-.514-.96-2.12-3.6-3.342-5.58-4.112-7.08-4.562-7.76-4.626-7.84-4.304-7.32-3.598-6.1-2.506-4.32-1.028-1.72-.256-.46-.258-.38-.32-.44-.322-.4-.322-.38-.32-.38-.386-.4-.322-.3-.45-.4-.384-.32z" fill="#cb2633"/><path d="M245.242 29.06c-2.83-2.68-6.752-4.24-11.766-4.62l10.03-9.5c.964-.9 1.736-1.96 2.186-3 .45-1.1.708-2.2.708-3.24 0-3.06-1.544-6.7-8.938-6.7h-24.048v8.72h22.634l-16.332 15.4v6.24h10.674c3.278 0 5.85.86 7.65 2.48 1.802 1.62 2.702 3.84 2.702 6.62 0 3.06-.966 5.4-2.894 7.04-1.994 1.62-4.886 2.46-8.68 2.46H212V60h19.418c2.7 0 5.208-.38 7.458-1.24 2.25-.84 4.244-2.08 5.916-3.7 1.672-1.62 3.022-3.58 3.858-5.72.9-2.22 1.35-4.68 1.35-7.36 0-5.58-1.608-9.88-4.758-12.92z" fill="#c74045" fillRule="nonzero"/></svg>
				</Link>
				<button type="button" className="header__toggle" onClick={toggleNav}>
					Open menu
					{navOpen ? (
						<svg viewBox="0 0 14 14" width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path fill="#bd1127" d="M13 10.88a.999.999 0 010 1.413l-.707.707a.999.999 0 01-1.414 0L6.646 8.768 2.414 13A.999.999 0 011 13l-.707-.707a.999.999 0 010-1.414l4.232-4.232L.293 2.414A.999.999 0 01.293 1L1 .293a.999.999 0 011.414 0l4.232 4.232L10.88.293a.999.999 0 011.414 0L13 1a.999.999 0 010 1.414L8.768 6.647z"/></svg>
					) : (
						<svg viewBox="0 0 4 16" width="4" height="16" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 8)"/><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 2)"/><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 -4)"/></svg>
					)}
				</button>
				<div className="header__title">3D Product Explorer</div>
				<div className="header__nav">
					<a href="https://www.racom.eu/eng/products/microwave-link.html" className="header__link" target="_blank" rel="noreferrer">
						Product Page
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="#bd1127" d="M10.332 3.141L5.515 9.564c-.14.14-.07.349 0 .489l.489.488c.14.14.349.14.488 0l6.423-4.817L14.38 7.19c.21.21.558.07.558-.209L15.986.419c.07-.21-.14-.419-.418-.419L9.005 1.047c-.279.07-.418.419-.209.559zm2.932 10.402c0 .21-.14.35-.35.35H2.445c-.21 0-.35-.14-.35-.35V3.072c0-.21.14-.35.35-.35h5.514L5.864.629h-3.42C1.116.628 0 1.745 0 3.072v10.471c0 1.327 1.117 2.443 2.443 2.443h10.472c1.326 0 2.443-1.116 2.443-2.443v-3.42l-2.094-2.095z"/></svg>
					</a>
					<Button
						text="Get Quote"
						to="https://www.racom.eu/eng/products/rfp.html#load(product=ray)"
					/>
				</div>
			</div>
			<Nav open={navOpen} isHomepage={!!isHomepage} setNavOpen={setNavOpen} />
		</div>
	);
}

export default Header;
